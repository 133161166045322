(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.IconSkin'] = {
  "react": [
    [
      "div",
      "image",
      [],
      {}
    ],
    [
      "div",
      "svg",
      [],
      {}
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageNewSkin"
    },
    "svg": {
      "skin": "skins.viewer.VectorImageShapeBasicSkin"
    }
  },
  "css": {
    "%": "overflow:hidden;",
    "%image": "position:static;box-shadow:#000 0 0 0;",
    "%svg": "position:static;box-shadow:#000 0 0 0;",
    "%[data-state~=\"image\"] %svg": "display:none;",
    "%[data-state~=\"svg\"] %image": "display:none;",
    "%[data-state~=\"svg\"] %svg": "width:100%;height:100%;"
  }
}

        return skins;
    }));