define([
    'lodash',
    'prop-types',
    'skins',
    'image-client-api',
    'santa-components',
    'svgShape',
    'icon/skins/skins.json',
    'componentsCore'
], function (
    _,
    PropTypes,
    skinsPackage,
    imageClientLib,
    santaComponents,
    svgShape,
    skinsJson,
    componentsCore
) {
    'use strict';

    function shouldCreateImageChild(compData) {
        return compData && compData.type === 'Image';
    }

    function getStateFromProps(props) {
        return {
            $iconType: shouldCreateImageChild(props.compData) ? 'image' : 'svg'
        };
    }

    const icon = {
        displayName: 'Icon',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            id: PropTypes.string,
            compData: PropTypes.object.isRequired,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            shouldRenderSrc: santaComponents.santaTypesDefinitions.Media.shouldRenderSrc,
            svgProps: PropTypes.object
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes),
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(svgShape.vectorImage)
        ),

        getInitialState() {
            return getStateFromProps(this.props);
        },

        componentWillReceiveProps(nextProps) {
            const state = getStateFromProps(nextProps);

            if (!_.isMatch(this.state, state)) {
                this.setState(state);
            }
        },

        createImage() {
            return this.createChildComponent(this.props.compData, 'core.components.Image', 'image', {
                ref: 'image',
                id: `${this.props.id}image`,
                imageData: this.props.compData,
                containerWidth: this.props.iconSize,
                containerHeight: this.props.iconSize,
                shouldRenderSrc: this.props.shouldRenderSrc,
                containerId: this.props.id,
                wixImageLayout: this.props.isResponsive,
                displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL
            });
        },

        createSvg() {
            return this.createChildComponent(this.props.compData, 'wysiwyg.viewer.components.VectorImage', 'svg', _.merge({
                ref: 'svg',
                id: `${this.props.id}svg`,
                svgId: this.props.compData.svgId,
                strokeWidth: 0,
                compProp: {
                    displayMode: imageClientLib.fittingTypes.SCALE_TO_FIT
                },
                shapeStyle: {
                    width: this.props.iconSize,
                    height: this.props.iconSize
                }
            }, this.props.svgProps || {}));
        },

        getSkinProperties() {
            const shouldRenderImage = this.state.$iconType === 'image';

            return {
                '': {},
                image: shouldRenderImage ? this.createImage() : null,
                svg: !shouldRenderImage ? this.createSvg() : null
            };
        },

        getDefaultSkinName() {
            return 'wysiwyg.viewer.skins.IconSkin';
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.Icon', icon);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return icon;
});
